import auth0 from "auth0-js";
import { navigate } from "gatsby";

const isBrowser = typeof window !== "undefined";

const { location: { host, protocol } = {}} = isBrowser && window;

const auth = isBrowser
  ? new auth0.WebAuth({
    domain: `${process.env.GATSBY_AUTHO_DOMAIN}`,
    clientID: `${process.env.GATSBY_AUTHO_CLIENT_ID}`,
    redirectUri: `${protocol}//${host}/callback`,
    responseType: "token id_token",
    scope: "openid profile email",
  })
  : {};

export const isAuthenticated = () => {
  if (!isBrowser) return;
  return localStorage.getItem("isLoggedIn") === "true";
};

export const login = () => {
  if (!isBrowser) return;
  auth.authorize();
};

const setSession = (cb = () => { }) => (err, authResult) => {
  if (err) {
    navigate("/");
    cb();
    return;
  }

  if (authResult && authResult.accessToken && authResult.idToken) {
    localStorage.setItem("isLoggedIn", true)
    localStorage.setItem("access_token", JSON.stringify(authResult.accessToken))
    navigate("/login");
    cb();
  }
};


export const silentAuth = callback => {
  if (!isAuthenticated()) return callback()
  auth.checkSession({}, setSession(callback))
};

export const getUser = (cb) => {
  const token = JSON.parse(localStorage.getItem("access_token"));
  if (token) {
    auth.client.userInfo(token, function (err, user) {
      if (!err) cb(user);
    });
  }
};

export const handleAuthentication = () => {
  if (!isBrowser) return;
  auth.parseHash(setSession());
};


export const logout = async () => {
 await Promise.all([
    localStorage.setItem("isLoggedIn", false),
    localStorage.clear("access_token"),
    auth.logout()
  ])
  return;
};